import React from 'react';
import styles from './styles.module.css';
//Twitter logos
import BlackTwitterLogo from '/assets/icon/twitter.svg';
import WhiteTwitterLogo from '/assets/icon/twitterf.svg';
import GrayTwitterLogo from '/assets/icon/socials-grey/twitter.svg';
//Discord logos
import WhiteDiscordLogo from '/assets/icon/discord-bg-white.svg';
import GrayDiscordLogo from '/assets/icon/socials-grey/discord.svg';
import BlackDiscordLogo from '/assets/icon/discord.svg';
//Instagram logos
import WhiteInstagramLogo from '/assets/icon/instagram-bg-white.svg';
import GrayInstagramLogo from '/assets/icon/socials-grey/instagram.svg';
import BlackInstagramLogo from '/assets/icon/instagram.svg';
//Facebook logos
import WhiteFacebookLogo from '/assets/icon/facebook-bg-white.svg';
import GrayFacebookLogo from '/assets/icon/socials-grey/facebook.svg';
import BlackFacebookLogo from '/assets/icon/facebook.svg';
//Tiktok logos
import WhiteTiktokLogo from '/assets/icon/tiktok-bg-white.svg';
import GrayTiktokLogo from '/assets/icon/socials-grey/tiktok.svg';
import BlackTiktokLogo from '/assets/icon/tiktok.svg';
//Twitch logos
import WhiteTwitchLogo from '/assets/icon/twitch-bg-white.svg';
import GrayTwitchLogo from '/assets/icon/socials-grey/twitch.svg';
import BlackTwitchLogo from '/assets/icon/twitch.svg';
//Telegram logos'/assets/icon/facebook.svg'
import WhiteTelegramLogo from '/assets/icon/telegram-bg-white.svg';
import GrayTelegramLogo from '/assets/icon/socials-grey/telegram.svg';
import BlackTelegramLogo from '/assets/icon/telegram.svg';

interface SocialIconsProps {
    href?: string;
    img?: string;
    color?: 'white' | 'black' | 'gray';
    icon?: string;
    width?: number | string;
    height?: number | string;
}

/**
 * Primary UI component for user interaction
 */
export const SocialIcons = ({
    img,
    href,
    color = 'black',
    icon = 'twitter',
    width = 45,
    height,
    ...props
}: SocialIconsProps) => {
    const coloredLogos = {
        twitter: {
            white: '/assets/icon/twitterf.svg',
            black: '/assets/icon/twitter.svg',
            gray: '/assets/icon/socials-grey/twitter.svg',
        },
        discord: {
            white: '/assets/icon/discord-bg-white.svg',
            black: '/assets/icon/discord.svg',
            gray: '/assets/icon/socials-grey/discord.svg',
        },
        facebook: {
            white: '/assets/icon/facebook-bg-white.svg',
            black: '/assets/icon/facebook.svg',
            gray: '/assets/icon/socials-grey/facebook.svg',
        },
        twitch: {
            white: '/assets/icon/twitch-bg-white.svg',
            black: '/assets/icon/twitch.svg',
            gray: '/assets/icon/socials-grey/twitch.svg',
        },
        telegram: {
            white: '/assets/icon/telegram-bg-white.svg',
            black: '/assets/icon/telegram.svg',
            gray: '/assets/icon/socials-grey/telegram.svg',
        },
        tiktok: {
            white: '/assets/icon/tiktok-bg-white.svg',
            black: '/assets/icon/tiktok.svg',
            gray: '/assets/icon/socials-grey/tiktok.svg',
        },
        instagram: {
            white: '/assets/icon/instagram-bg-white.svg',
            black: '/assets/icon/instagram.svg',
            gray: '/assets/icon/socials-grey/instagram.svg',
        },
    };
    // Checks to see if the image is an object or not. If it is then it means the image.src needs to be used for img src
    // const isImgObj =
    //     typeof coloredLogos[icon][color] === 'object' &&
    //     !Array.isArray(coloredLogos[icon][color]) &&
    //     coloredLogos[icon][color] !== null;
    return (
        <a href={href} target="_blank" {...props}>
            <img
                src={
                    // Checks to see if user passed a custom image and then replaces the default if custom image exists
                    img ? img : coloredLogos[icon][color]
                }
                width={width}
                height={height}
                alt={icon}
            />
        </a>
    );
};
