import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false, color: '#A12CEB' });

const useProgressBar = (router) => {
    const handleStart = () => {
        NProgress.start();
    };

    const handleStop = () => {
        NProgress.done();
    };

    const initiateProgressBar = () => {
        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);
    };

    const terminateProgressBar = () => {
        router.events.off('routeChangeStart', handleStart);
        router.events.off('routeChangeComplete', handleStop);
        router.events.off('routeChangeError', handleStop);
    };

    return [initiateProgressBar, terminateProgressBar];
};

export default useProgressBar;
