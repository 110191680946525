import NavbarScroller from '../navbar';
import Footer from '../footer';
import { useSession } from 'next-auth/react';
import styles from './layout.module.css';

export default function Layout({ children }: { children: React.ReactNode }) {
    const { data: nextauthSession } = useSession();

    return (
        <div className={`${styles.container} row m-0 p-0 w-100`}>
            <div className="col-12 m-0 p-0">
                <NavbarScroller
                    signIn={nextauthSession?.isAuthenticated as boolean}
                    isCreator={nextauthSession?.isCreator as boolean}
                />
            </div>
            {/* <StickyMobileMenu /> */}
            <div className={`col-12 m-0 p-0 w-100 ${styles.children}`}>
                {children}
            </div>
            <div className="col-12 m-0 p-0">
                <Footer
                    isAuthenticated={
                        nextauthSession?.isAuthenticated as boolean
                    }
                    username={nextauthSession?.username}
                />
            </div>
        </div>
    );
}
