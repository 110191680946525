import Link from 'next/link';
import styled from 'styled-components';
// import menuOptionStyles from './menu-options.module.css';

const MenuOptions = ({ homeUrl, setMenuActive }) => {
    return (
        <>
            <Link href={homeUrl}>
                <OverlayAnchor
                    onClick={() => setMenuActive(false)}
                    className="cursor-pointer"
                >
                    Home
                </OverlayAnchor>
            </Link>
            {/* </MediaQuery> */}
            <Link href="/faqs">
                <OverlayAnchor
                    onClick={() => setMenuActive(false)}
                    className="cursor-pointer mb-5"
                >
                    FAQs
                </OverlayAnchor>
            </Link>

            {/* <OverlayAnchor className="cursor-pointer">About us</OverlayAnchor> */}
        </>
    );
};

const OverlayAnchor = styled.a`
    font-style: normal;
    font-weight: normal;
    display: flex;
    font-size: 24px;
    margin-top: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #fefefe !important;
    padding: 0.5rem 0;
`;

export default MenuOptions;
