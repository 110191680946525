import { createSlice } from '@reduxjs/toolkit';
import { Session } from 'next-auth';

interface AppSession extends Session {
    newDisplayImage: string;
    reduxSessionSet: boolean;
}

const initialState: AppSession = {
    userId: '',
    email: '',
    username: '',
    passwordSet: undefined,
    isVerified: undefined,
    isCreator: undefined,
    displayImage: '',
    name: '',
    about: '',
    contactEmail: '',
    isProfileSet: undefined,
    isAuthenticated: false,
    newDisplayImage: '',
    reduxSessionSet: false,
    expires: null,
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        updateDisplayImg: (state, action) => {
            const payload = action.payload;
            console.log('slice - updateDisplayImg', payload);

            return {
                ...state,
                displayImage: payload,
            };
        },
        updateSession: (state, action) => {
            const payload = action.payload;
            console.log('slice - updateSession', payload);

            // update redux session only for first time and when forced
            if (!payload.forceUpdate && state.reduxSessionSet) return state;

            // set the redux session only once
            const { forceUpdate, ...sessionData } = payload;
            return {
                ...state,
                ...sessionData,
                reduxSessionSet: true,
            };
        },
        // TODO (test): Add action to update profile
        updateProfile: (state, action) => {
            const payload = action.payload;
            console.log('slice - updateProfile', payload);

            //update user profile after it is updated from settings
            const { ...profileData } = payload;
            return {
                ...state,
                ...profileData,
            };
        },
        clearSession: (state) => {
            console.log('slice - clearSession');

            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { updateDisplayImg, updateSession, updateProfile, clearSession } =
    sessionSlice.actions;

export default sessionSlice.reducer;
