let amplitude: any;

const initAmplitude = () => {
    console.log(':: Amplitude [Initialized] ::');
    const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
    if (process.browser) {
        amplitude = require('amplitude-js').default;
        amplitude.getInstance().init(AMPLITUDE_API_KEY);
    }
};

const setAmplitudeSessionId = (userId: string) => {
    console.log(`:: Amplitude [Set Session] | ${userId} ::`);
    if (process.browser) {
        amplitude.getInstance().setUserId(userId);
    }
};

const amplitudeEvent = (name: string, params?: {}) => {
    console.log(':: Amplitude [Log Event] | ', name, params, ' ::');
    if (process.browser) {
        amplitude.getInstance().logEvent(name, params);
    }
};

const getInstance = () => {
    console.log(':: Amplitude [Instance] ::');
    if (process.browser) {
        return amplitude;
    }
};

export const amplitudeUtil = {
    getInstance: getInstance,
    init: initAmplitude,
    setSession: setAmplitudeSessionId,
    log: amplitudeEvent,
};
