import { createGlobalStyle } from 'styled-components'

export const lightTheme = {
    backgroundColor: '#FEFEFE',
    textColor: '#ffffff',
    fontFamily: 'Tahoma, Helvetica, Arial, Roboto, sans-serif',
    primary: '#A12CEB',
}

export const darkTheme = {
    backgroundColor: '#333333',
    textColor: '#000000',
    primary: '#77ccdd',
}

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }: any) => theme.backgroundColor};
    color: ${({ theme }) => theme.primary};
    font-family: ${({ theme }) => theme.fontFamily};
    transition: all 0.50s linear;
    margin: 0;
  }
`
