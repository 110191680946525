import { useState, useEffect, useRef } from 'react';
/**
 * Hook for handling closing when clicking outside of an element
 */
export function useDetectOutsideClick() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = useRef(null);

    const handleOnClickOutside = (e) => {
        const elem = e.target;

        if (ref.current && !ref.current.contains(elem)) {
            if (elem.localName !== 'a') setIsOpen(false);
        }
    };

    useEffect(() => {
        // If the dropdown is open then add a click listener to the DOM
        if (isOpen) {
            document.addEventListener('mousedown', handleOnClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleOnClickOutside);
        };
    }, [isOpen]);

    return {
        isOpen,
        setIsOpen,
        ref,
    };
}
