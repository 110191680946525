import TagManager from 'react-gtm-module';

const useTagManager = () => {
    const initiateTagManager = () => {
        if (process.env.NODE_ENV !== 'development') {
            const gtmId =
                (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string) ??
                undefined;
            if (gtmId) {
                console.log('Initializing Tag Manager');
                TagManager.initialize({ gtmId: gtmId });
            } else {
                console.log('Tag Manager Id not found');
            }
        } else {
            console.log('Tag Manager is disabled for dev environment');
        }
    };

    return initiateTagManager;
};

export default useTagManager;
