import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Constants from '../../utils/constants';
import Image from 'next/image';
import Link from 'next/link';
import utilStyles from '../../styles/utils.module.css';
// import Search from '../search';
import DropdownMenu from '../dropdown-menu';
import MenuOptions from '../menu-options';
import { device } from '../../utils/device';
import styles from './navbar.module.css';
import { useRouter } from 'next/router';
import { useSession, signOut } from 'next-auth/react';
import { withRouter } from 'next/router';
// import ProfileSetupModal from '../modals/custom-modal';
import { clearSession } from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { RootState } from '../../redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const NavOptions = dynamic(() => import('../dynamic-wrapper'), {
    ssr: false,
});

const NavbarScroller = (props: {
    router;
    signIn: boolean;
    isCreator: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { data: session } = useSession();
    const reduxSession = useSelector((state: RootState) => state.session);
    const [isActive, setIsActive] = React.useState(false);
    const router = useRouter();
    // const [open, setOpen] = React.useState(false);
    const [dp, setDp] = useState(reduxSession.displayImage);
    const [homeUrl, setHomeUrl] = useState('/');

    useEffect(() => {
        // if (reduxSession.newDisplayImage) setDp(reduxSession.newDisplayImage);
        setDp(reduxSession.displayImage);
        return () => {
            setDp(reduxSession.displayImage);
        };
    }, [reduxSession]);

    // useEffect(() => {
    //     setUpProfile();
    // }, []);

    useEffect(() => {
        if (session) {
            setHomeUrl('/home');
        } else {
            setHomeUrl('/');
        }
    }, [session]);

    // const setUpProfile = () => {
    //     if (
    //         session &&
    //         !session?.isProfileSet &&
    //         !document.cookie
    //             .split(';')
    //             .some((item) => item.trim().startsWith('setupProfileLater=')) &&
    //         !router.asPath.includes('/settings') &&
    //         !router.asPath.includes('/login') &&
    //         !router.asPath.includes('/faqs')
    //     )
    //         setOpen(true);
    // };

    // const handleDoItLater = () => {
    //     // set cookie for 24hrs
    //     var d = new Date();
    //     d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    //     document.cookie = 'setupProfileLater=true; expires=' + d.toUTCString();
    //     setOpen(false);
    // };
    function handleLogin() {
        setIsActive(!isActive);
        let callbackUrl = router.asPath as string;
        if (callbackUrl === '/' || callbackUrl === 'accounts/login') {
            callbackUrl = '/home';
        }
        router.push(
            '/accounts/login' +
                (callbackUrl ? `?callbackUrl=${callbackUrl}` : '')
        );
    }

    function createNft() {
        router.push('/createnewnft');
    }

    function renderCreateButton() {
        if (session) {
            return (
                <>
                    {props.isCreator ? (
                        <div>
                            <TransparentButton onClick={() => createNft()}>
                                Create
                            </TransparentButton>
                        </div>
                    ) : null}
                </>
            );
        } else {
            return (
                <div className="mb-5 mt-3 my-md-3">
                    <PinkButton onClick={() => handleLogin()}>
                        Sign in / up
                    </PinkButton>
                </div>
            );
        }
    }

    const onSignOut = async () => {
        dispatch(clearSession());
        signOut({
            redirect: true,
            callbackUrl: router.asPath,
        });
    };
    function signOutHandler() {
        onSignOut();
        setIsActive(!isActive);
    }
    return (
        <Navbar
            className={
                router.pathname === '/' && !props.signIn
                    ? styles.navBackground
                    : null
            }
        >
            <Link href={homeUrl}>
                <div className={utilStyles.logoPosition}>
                    <Image
                        priority
                        className={utilStyles.logoIcon}
                        src={Constants.LOGO_IMAGE_PATH}
                        height={Constants.LOGO_IMAGE_HEIGHT}
                        width={Constants.LOGO_IMAGE_WIDTH}
                    />
                    <div style={{ marginLeft: '11px' }}>
                        {router.pathname === '/' && !props.signIn ? (
                            <Image
                                priority
                                className={`${utilStyles.logoText} mt-1`}
                                src="/images/superfandom-white.svg"
                                height={Constants.LOGO_TEXT_HEIGHT}
                                width={Constants.LOGO_TEXT_WIDTH}
                            />
                        ) : (
                            <Image
                                priority
                                className={`${utilStyles.logoText} mt-1`}
                                src={Constants.LOGO_TEXT_PATH}
                                height={Constants.LOGO_TEXT_HEIGHT}
                                width={Constants.LOGO_TEXT_WIDTH}
                            />
                        )}
                    </div>
                </div>
            </Link>
            {/* <SearchWrapper>
                <Search />
            </SearchWrapper> */}
            <NavOptions>
                <NavOptionDiv>
                    <Link href={'/faqs'}>
                        <Li
                            className={`${
                                router.pathname === '/' && !props.signIn
                                    ? styles.navIcon
                                    : null
                            } cursor-pointer `}
                        >
                            FAQs
                        </Li>
                    </Link>
                    <Li key={'separator'}>
                        <Image
                            priority
                            src="/images/line-separator.svg"
                            height={Constants.LOGO_IMAGE_HEIGHT}
                            width={1}
                        />
                    </Li>
                    {renderCreateButton()}
                    {session && (
                        <DropdownMenu
                            displayImage={dp}
                            username={session.username}
                            onSignOut={onSignOut}
                        />
                    )}
                </NavOptionDiv>
            </NavOptions>
            <NonDesktop>
                {/* showing white icon with black background for non logged in state on the homepage  */}
                {router.pathname === '/' && !props.signIn ? (
                    <button
                        style={{ background: '#201627' }}
                        onClick={() => setIsActive(!isActive)}
                        className={`${utilStyles.hamburgerButton} mt-sm-2`}
                    >
                        {!isActive ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faBars as IconProp}
                                    size="lg"
                                    className="text-white"
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </button>
                ) : (
                    <div className="d-flex align-items-center">
                        {session && (
                            <Link href={'/' + session?.username}>
                                <div
                                    className={` ${utilStyles.profileLoginImgHeader} mt-sm-2`}
                                >
                                    <img
                                        className="object-fit"
                                        src={
                                            dp.length > 0
                                                ? dp
                                                : Constants.PROFILE_IMAGE_PATH
                                        }
                                        height={Constants.PROFILE_IMAGE_HEIGHT}
                                        width={Constants.PROFILE_IMAGE_WIDTH}
                                    />
                                </div>
                            </Link>
                        )}
                        <button
                            onClick={() => setIsActive(!isActive)}
                            className={`${utilStyles.hamburgerButton} mt-sm-2`}
                        >
                            {!isActive ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faBars as IconProp}
                                        size="lg"
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </button>
                    </div>
                )}

                {isActive && (
                    <Overlay>
                        <div
                            className={`${styles.navPadding} d-flex justify-content-between align-items-center`}
                        >
                            <Link href="/">
                                <a className={utilStyles.overlayLogoPosition}>
                                    <Image
                                        className={utilStyles.logoIcon}
                                        src={Constants.LOGO_IMAGE_PATH}
                                        height={Constants.LOGO_IMAGE_HEIGHT}
                                        width={Constants.LOGO_IMAGE_WIDTH}
                                    />
                                    <div style={{ marginLeft: '11px' }}>
                                        <Image
                                            className={`mt-1 ${utilStyles.logoText}`}
                                            src={Constants.LOGO_WHITE_TEXT_PATH}
                                            height={
                                                Constants.LOGO_WHITE_TEXT_HEIGHT
                                            }
                                            width={
                                                Constants.LOGO_WHITE_TEXT_WIDTH
                                            }
                                        />
                                    </div>
                                </a>
                            </Link>
                            <button
                                onClick={() => setIsActive(!isActive)}
                                className={`${utilStyles.menuCloseButton}`}
                                style={{
                                    marginTop: '0px',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark as IconProp}
                                    size="lg"
                                    className="text-white"
                                />
                            </button>
                        </div>
                        <div
                            className={`h-100 d-flex align-items-center w-100`}
                        >
                            <div className="w-100 p-4 align-items-end">
                                {/* <Search /> */}

                                <MenuOptions
                                    homeUrl={homeUrl}
                                    setMenuActive={setIsActive}
                                />
                                {!session && renderCreateButton()}
                                {session && (
                                    <>
                                        <hr
                                            className={`${styles.hrLine} my-4`}
                                        />
                                        <Link href="/settings">
                                            <h6
                                                onClick={() =>
                                                    setIsActive(!isActive)
                                                }
                                                className="font-24-400 mt-4 pt-2  cursor-pointer color-white"
                                            >
                                                Profile setting
                                            </h6>
                                        </Link>
                                        <h6
                                            onClick={signOutHandler}
                                            className="font-24-400 mt-4 pt-2 mb-4 cursor-pointer color-white"
                                        >
                                            Sign out
                                        </h6>
                                    </>
                                )}

                                <div className="d-flex align-items-center justify-content-center">
                                    <button
                                        className="text-white text-center"
                                        onClick={() => setIsActive(!isActive)}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            fontSize: '16px',
                                            paddingLeft: '0px',
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark as IconProp}
                                            size="1x"
                                            className="text-white me-1"
                                        />
                                        <span>close</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Overlay>
                )}
            </NonDesktop>

            {/* <ProfileSetupModal
                isModalOpen={open}
                handleModalClose={() => setOpen(false)}
            >
                <div className="py-3 px-4" style={{ maxWidth: '420px' }}>
                    <div className="row">
                        <div className="col-8 px-0 m-0">
                            <h4 className="color-white font-48 m-0">
                                Great to have you here!
                            </h4>
                        </div>
                        <div className="col-4 m-0 p-0">
                            <Image
                                priority
                                src={Constants.LOGO_IMAGE_PATH}
                                alt="logo"
                                objectFit="contain"
                                width="352"
                                height="352"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <p className="col-12 p-0 color-white font-20thin my-3">
                            Get started by setting up your profile
                        </p>
                        <Link href="/settings">
                            <button
                                onClick={handleDoItLater}
                                className={`br-25 w-100 py-3 my-4 color-white bg-purple font-16 border-0`}
                            >
                                Set up my profile
                            </button>
                        </Link>
                        <button
                            onClick={handleDoItLater}
                            className="text-white border-0 bg-transparent font-16 text-center"
                        >
                            I’ll do it later
                        </button>
                    </div>
                </div>
            </ProfileSetupModal> */}
        </Navbar>
    );
};

const Navbar = styled.nav`
    background: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.primary};
    display: flex;
    align-items: center;
    height: 56px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    a {
        color: #201627;
        text-decoration: none;
    }
    padding: 10px 25px;
    @media ${device.tablet} {
        height: 96px;
        padding: 0px 35px;
    }
`;

const Brand = styled.a`
    font-weight: bold;
    font-style: italic;
    margin-left: 1rem;
    padding-right: 1rem;
`;

const NavOptionDiv = styled.div`
    @media ${device.laptop} {
        display: flex;
    }
    display: none;
    flex-wrap: nowrap;
    align-items: center;
    /* overflow-x: hidden; */
    -webkit-overflow-scrolling: touch;
`;

const Li = styled.li`
    flex: 0 0 auto;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    color: #201627;
    display: flex;
    margin: 0 1.125rem;
    white-space: nowrap;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    // line-height: 18px;
    letter-spacing: 0.5px;
`;

const SearchWrapper = styled.div`
    // Switch to rows on large devices
    @media ${device.laptop} {
        display: flex;
    }
    display: none;
`;

const NonDesktop = styled.div`
    @media ${device.laptop} {
        display: none;
    }
    display: flex;
`;

const Overlay = styled.div`
    /* The Overlay (background) */
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    overflow: hidden;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: #201627;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
`;

const PinkButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 60px;
    width: 100%;
    height: 56px;
    border: 0;

    /* Pink */
    background: #e54fc4;
    border-radius: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    /* White */
    color: #fefefe;
    white-space: nowrap;
`;

const TransparentButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 60px;
    width: 100%;
    height: 56px;
    border: 1px solid #e54fc4;

    /* Pink */
    background: transparent;
    border-radius: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    /* White */
    color: #e54fc4;
    white-space: nowrap;
`;

export default withRouter(NavbarScroller);
