import React from 'react';
import { SentryUtil } from '../../utils/sentry/sentry.util';
import Custom500 from '../error-pages/500';

interface Props {}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };

        this.handleTryAgain = this.handleTryAgain.bind(this);
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error, errorInfo) {
        // Log errors to sentry
        SentryUtil.logError(error, errorInfo);
    }

    handleTryAgain() {
        this.setState({
            hasError: false,
        });
    }

    render() {
        if (this.state.hasError) {
            return <Custom500 handleOnClick={this.handleTryAgain} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
