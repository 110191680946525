// LOGO icon specs
export const LOGO_IMAGE_WIDTH = 31;
export const LOGO_IMAGE_HEIGHT = 56;
export const LOGO_IMAGE_PATH = '/assets/logos/logo.png';

// logos on modals
export const MODAL_LOGO_IMAGE_WIDTH = 44;
export const MODAL_LOGO_IMAGE_HEIGHT = 80;

export const LOGO_FOOTER_IMAGE_WIDTH = 50.1;
export const LOGO_FOOTER_IMAGE_HEIGHT = 80;

export const LOGO_TEXT_WIDTH = 183.92;
export const LOGO_TEXT_HEIGHT = 40;
export const LOGO_TEXT_PATH = '/images/superfandom.svg';

export const LOGO_WHITE_TEXT_WIDTH = 183.92;
export const LOGO_WHITE_TEXT_HEIGHT = 40;
export const LOGO_WHITE_TEXT_PATH = '/images/superfandom-white.svg';

export const LOGO_WHITE_FOOTER_TEXT_WIDTH = 262.74;
export const LOGO_WHITE_FOOTER_TEXT_HEIGHT = 40.01;

// USER PROFILE DROPDOWN
export const PROFILE_IMAGE_WIDTH = 45;
export const PROFILE_IMAGE_HEIGHT = 45;
export const PROFILE_IMAGE_PATH = '/images/basicprofile.svg';

export const DROPDOWN_IMAGE_WIDTH = 15;
export const DROPDOWN_IMAGE_HEIGHT = 7;
export const DROPDOWN_IMAGE_PATH = '/images/arrow-down.svg';

// RESPONSIVE LAYOUT ICONS
export const MENU_HAMBURGER_PATH = '/images/menu-hamburger.svg';
export const MENU_HAMBURGER_WIDTH = 32;
export const MENU_HAMBURGER_HEIGHT = 32;

export const MENU_CLOSE_PATH = '/images/close-menu.svg';
export const MENU_CLOSE_WIDTH = 18;
export const MENU_CLOSE_HEIGHT = 30;

// USER SIGNUP
export const USER_SIGNUP_BACKDROP = '/images/sign-in-cover.svg';
export const CLOSE_ICON_BLACK = '/images/close-black.svg';
export const GOOGLE_SIGN_IN_BTN = '/images/google-sign-in.svg';
export const SIGNIN_SEPARATOR = '/images/signin-or-separator.svg';

// NFT CARD
export const NFT_CARD_MEDIA_WIDTH = 360;
export const NFT_CARD_MEDIA_HEIGHT = 360;
