import React from 'react';
import styles from './footer.module.css';
import FooterLogo from '../footer-logo';
import styled from 'styled-components';
import Link from 'next/link';
import { Subtitle1LightGrey } from '../styled-typography';
import { SocialIcons } from '../icons/socialIcons';
import WhiteTwitterLogo from '../../public/assets/icon/twitterf.svg';

export default function Footer({
    username,
    isAuthenticated = false,
}: {
    username: string;
    isAuthenticated: boolean;
}) {
    return (
        <div className={`${styles.mainDiv}`}>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column align-items-start flex-md-row justify-content-between">
                    <div className="col-lg-6">
                        <FooterLogo />
                    </div>

                    <div
                        id="auth-identifier"
                        data-auth={isAuthenticated ? 'true' : 'false'}
                        className="col-lg-3 mt-5 mt-md-3 mt-lg-4"
                    >
                        <Link href="/faqs">
                            <FooterNavLink>FAQs</FooterNavLink>
                        </Link>
                        <Link
                            href={
                                isAuthenticated
                                    ? `/${username}`
                                    : '/accounts/login'
                            }
                        >
                            <FooterNavLink className="mb-0">
                                {isAuthenticated ? 'My Account' : 'Sign Up'}
                            </FooterNavLink>
                        </Link>
                    </div>
                    <div
                        className={`col-lg-3 mt-5 mt-md-3 mt-lg-4 align-self-center justify-self-end`}
                    >
                        <div className="d-flex justify-content-end">
                            <div className={`${styles.marginIcon}`}>
                                <SocialIcons
                                    href="https://t.me/superfandom"
                                    color="white"
                                    icon="telegram"
                                />
                            </div>
                            <div className={`${styles.marginIcon}`}>
                                <SocialIcons
                                    href="https://www.instagram.com/superfandomofficial/"
                                    color="white"
                                    icon="instagram"
                                />
                            </div>
                            <div>
                                <SocialIcons
                                    href="https://twitter.com/superfandom_nft/"
                                    color="white"
                                    icon="twitter"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.lineBreakContainer}>
                    <div className={styles.lineBreak}></div>
                </div>
                <TermsAndConditions className="d-flex flex-column flex-md-row justify-content-between">
                    <div
                        className="col m-0 p-0"
                        style={{ fontSize: '12px', color: '#FEFEFE' }}
                    >
                        Superfandom &copy; Copyright {new Date().getFullYear()}.
                        (a product of Rare Sense Inc.)
                    </div>
                    <div className="col-lg-3 m-0 p-0 mt-3 mt-md-0 ">
                        <div className="row justify-content-lg-end">
                            <Link href="/terms-of-service">
                                <div className="col-auto color-white cursor-pointer">
                                    Terms of Service
                                </div>
                            </Link>
                            <Link href="/privacy-policy">
                                <div className="col-auto color-white cursor-pointer">
                                    Privacy Policy
                                </div>
                            </Link>
                        </div>
                    </div>
                </TermsAndConditions>
            </div>
        </div>
    );
}

export const FooterNavLink = styled(Subtitle1LightGrey)`
    cursor: pointer;
`;

const TermsAndConditions = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;

    color: #fefefe;
`;
