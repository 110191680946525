import { createContext, ReactNode, useEffect } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import { useAppDispatch } from '../redux/store';
import { clearSession, updateSession } from '../redux/actions';
import { Session } from 'next-auth';
import { useRouter } from 'next/router';

export const SessionSyncContext = createContext<Session | null>(null);

export const SessionSyncProvider = ({ children }: { children: ReactNode }) => {
    const router = useRouter();

    const { data: nextauthSession, status } = useSession();

    const reduxSession = useSelector((state: RootState) => state.session);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (nextauthSession && !reduxSession.reduxSessionSet) {
            dispatch(updateSession({ ...nextauthSession }));
        } else if (status == 'unauthenticated') {
            dispatch(clearSession());
            signOut({
                redirect: true,
                callbackUrl: router.asPath,
            });
        }
        return () => {};
    }, [nextauthSession]);

    return (
        <SessionSyncContext.Provider value={{ ...nextauthSession }}>
            {children}
        </SessionSyncContext.Provider>
    );
};
