import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles.module.css';
import {
    LOGO_FOOTER_IMAGE_HEIGHT,
    LOGO_FOOTER_IMAGE_WIDTH,
    LOGO_IMAGE_PATH,
    LOGO_WHITE_FOOTER_TEXT_HEIGHT,
    LOGO_WHITE_FOOTER_TEXT_WIDTH,
    LOGO_WHITE_TEXT_PATH,
} from '../../utils/constants';

const FooterLogo = () => {
    return (
        <Link href="/">
            <div className={styles.logoPosition}>
                <Image
                    priority
                    src={LOGO_IMAGE_PATH}
                    width={LOGO_FOOTER_IMAGE_WIDTH}
                    height={LOGO_FOOTER_IMAGE_HEIGHT}
                />
                <div className={`${styles.logoText}`}>
                    <Image
                        priority
                        src={LOGO_WHITE_TEXT_PATH}
                        width={LOGO_WHITE_FOOTER_TEXT_WIDTH}
                        height={LOGO_WHITE_FOOTER_TEXT_HEIGHT}
                    />
                </div>
            </div>
        </Link>
    );
};
export default FooterLogo;
