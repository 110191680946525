import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/device';

// default body
export const P = styled.p`
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: #201627;
`;

export const PWhite = styled(P)`
    color: #ffffff;
`;

export const PMediumLightYellow = styled(P)`
    color: #ffe074;
`;

export const P2 = styled.p`
    margin-bottom: 8px;

    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;

    /* Black */

    color: #201627;
`;

export const P2White = styled(P2)`
    color: #ffffff;
`;

export const P2Grey4 = styled(P2)`
    color: #565159;
`;

export const P2Grey5 = styled(P2)`
    /* Grey-5 */

    color: #383639;
`;

// profile page - title
export const H2 = styled.h2`
    /* H2 */
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 88px;
    /* identical to box height, or 110% */

    letter-spacing: 0.5px;

    /* Black */

    color: #201627;
`;

export const H3 = styled.h3`
    /* H3 */

    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 74px;
    letter-spacing: 0.5px;

    /* Black */

    color: #201627;
`;
export const H3LightGrey = styled(H3)`
    color: #fefefe;
`;

export const H4 = styled.h4`
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 57px;
    letter-spacing: 0.5px;

    color: #201627;
`;

export const H4LightGrey = styled(H4)`
    color: #fefefe;
`;

export const H5 = styled.h5`
    font-family: Ubuntu;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.5px;

    /* Black */
    color: #201627;
`;

export const H5LightGrey = styled(H5)`
    color: #fefefe;
`;

// profile page - about heading
export const H6 = styled.h6`
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.5px;

    /* Black */
    color: #201627;
`;

export const H6LightGrey = styled(H6)`
    color: #fefefe;
`;

export const H6Purple = styled(H6)`
    color: #a12ceb;
`;

export const SubTitle1 = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    letter-spacing: 0.5px;

    color: #201627;
`;
export const Subtitle1LightGrey = styled(SubTitle1)`
    color: #fefefe;
`;

export const SubTitle2 = styled(SubTitle1)`
    font-size: 16px;
    line-height: 18px;
`;
export const Subtitle2LightGrey = styled(SubTitle2)`
    color: #fefefe;
`;

export const Subtitle2Grey3 = styled(SubTitle2)`
    /* Grey-3 */
    color: #747076;
`;

export const Subtitle3Grey3 = styled(SubTitle2)`
    /* Grey-3 */
    color: #747076;
    font-size: 12px;
`;

export const OverLine = styled.span`
    font-family: Ubuntu;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-align: left;
`;

export const OverLineGrey4 = styled(OverLine)`
    /* Grey-4 */
    color: #565159;
`;

export const OverLineGrey5 = styled(OverLine)`
    /* Grey-5 */
    color: #383639;
`;

export const OverLineTeal = styled(OverLine)`
    /* Teal */
    color: #6bd6d6;
`;

export const Caption = styled.div`
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.5px;

    /* Black */
    color: #201627;
`;

export const CardWrapper = styled.div`
    position: relative;
    margin-bottom: 30px;
`;

const Stack = styled.div`
    height: 80px;
    background-color: white;
    border: 2px solid #74dee4;
    border-radius: 24px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
`;

export const Stack1 = styled(Stack)`
    width: 95%;
    bottom: -11px;
    z-index: 2;
    box-shadow: 0px 2.83556px 4.72593px rgba(0, 0, 0, 0.25),
        0px 3.78074px 10.397px rgba(0, 0, 0, 0.1);

    @media (max-width: 500px) {
        bottom: -11px;
    }
`;

export const Stack2 = styled(Stack)`
    width: 90%;
    bottom: -22px;
    z-index: 1;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);

    @media (max-width: 500px) {
        bottom: -22px;
    }
`;
