import React from 'react';
import styled from 'styled-components';
import styles from './dropdown-menu.module.css';
import Image from 'next/image';
import Link from 'next/link';
import * as Constants from '../../utils/constants';
import { useDetectOutsideClick } from '../../hooks/useOnClickOutside';
import { useRouter } from 'next/router';
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
 */

export default function DropdownMenu({ displayImage, username, onSignOut }) {
    const router = useRouter();
    const { isOpen, setIsOpen, ref } = useDetectOutsideClick();
    const handleOnClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.dropdownContainer}>
            <div className="d-flex justify-content-center align-items-center">
                <div className={`${styles.dropdownButton} mx-3`}>
                    <img
                        onClick={() => router.push(`/${username}`)}
                        className="object-fit"
                        src={
                            displayImage.length > 0
                                ? displayImage
                                : Constants.PROFILE_IMAGE_PATH
                        }
                        height={Constants.PROFILE_IMAGE_HEIGHT}
                        width={Constants.PROFILE_IMAGE_WIDTH}
                    />
                </div>
                <div
                    ref={ref}
                    onClick={() => handleOnClick()}
                    className="cursor-pointer align-items-center"
                >
                    <Image
                        priority
                        src={Constants.DROPDOWN_IMAGE_PATH}
                        height={Constants.DROPDOWN_IMAGE_HEIGHT}
                        width={Constants.DROPDOWN_IMAGE_WIDTH}
                    />
                </div>
            </div>
            {isOpen && (
                <div className={styles.dropdownBox}>
                    <Ul>
                        <Li>
                            <Link href="/settings">
                                <a>Edit Profile</a>
                            </Link>
                        </Li>
                        <Li>
                            <a
                                className="text-black cursor-pointer"
                                onClick={onSignOut}
                            >
                                Sign Out
                            </a>
                        </Li>
                    </Ul>
                </div>
            )}
        </div>
    );
}

const Ul = styled.ul`
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
`;

const Li = styled.li`
    /* Auto Layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 32px;
    padding-right: 32px;

    position: static;
    width: auto;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 24px 0px;
`;
