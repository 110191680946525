import Head from 'next/head';
import { NextPage } from 'next';
import Layout from '../../components/layout';
import Image from 'next/image';
import styled from 'styled-components';

const Button = styled.button`
    transition: 0.3s;
    color: #fff;
    border: 0px;
    background: #e54fc4;
    padding: 10px 20px;
    border-radius: 25px;

    &:disabled {
        background: #ccc !important;
    }
`;

interface Props {
    handleOnClick: () => void;
}

const Custom500: NextPage<Props> = ({ handleOnClick }) => {
    const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;
    const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID;
    const topBannerDesc =
        'Discover and own experiences with your favorite creators';

    return (
        <>
            <Layout>
                <Head>
                    <title>Superfandom - {topBannerDesc}</title>
                    <meta property="og:url" content={`${APP_BASE_URL}`} />
                    <meta property="og:type" content="website" />
                    <meta property="fb:app_id" content={FB_APP_ID} />
                    <meta property="og:title" content={'Super Fandom'} />
                    <meta name="twitter:card" content="summary" />
                    <meta property="og:description" content={topBannerDesc} />
                </Head>

                <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                    <Image height={350} width={512} src="/images/img-404.svg" />
                    <p className="mt-4 text-black fs-4">
                        Oops, something went wrong!!!
                    </p>

                    <Button onClick={() => handleOnClick()}>Try Again</Button>
                </div>
            </Layout>
        </>
    );
};

export default Custom500;
