import React, { useEffect } from 'react';
import Script from 'next/script';
import { ThemeProvider } from 'styled-components';
import SnackbarProvider from 'react-simple-snackbar';
import { SWRConfig } from 'swr';
import fetcher from '../utils/fetch';
import { lightTheme, GlobalStyles } from '../theme.config';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import store, { persistor } from '../redux/store';
import { useRouter } from 'next/router';
import { SessionSyncProvider } from '../contexts/SessionSync.context';
import useTagManager from '../hooks/useTagManager';
import useProgressBar from '../hooks/useProgressBar';
import { amplitudeUtil } from '../utils/amplitude/client';
import { fullStoryScript } from '../utils/full-story';
import { hotjarExcludedRoutes, hotjarScript } from '../utils/hotjar';
import '../public/nprogress.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/index.css';
import ErrorBoundary from '../components/error-boundary';
import {
    load as loadIntercom,
    boot as bootIntercom,
} from '../utils/intercom/intercom';

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const [initiateProgressBar, terminateProgressBar] = useProgressBar(router);
    const initiateTagManager = useTagManager();
    let intercomOptions = {};

    useEffect(() => {
        initiateTagManager();
        amplitudeUtil.init();
    }, []);

    useEffect(() => {
        initiateProgressBar();
        return () => {
            terminateProgressBar();
        };
    }, [router.events]);

    useEffect(() => {
        const isRouteNft = router.pathname.includes('/nft/');
        intercomOptions = {
            hide_default_launcher: false,
        };
        loadIntercom();
        if (pageProps.session) {
            intercomOptions['name'] = pageProps.session.name;
            intercomOptions['email'] = pageProps.session.email;
            intercomOptions['user_id'] = pageProps.session.userId;
        } else {
            intercomOptions['name'] = null;
            intercomOptions['email'] = null;
            intercomOptions['user_id'] = null;
        }
        if (isRouteNft) {
            intercomOptions['hide_default_launcher'] = true;
        }

        bootIntercom(intercomOptions);
    }, [pageProps.session, router.pathname]);

    return (
        <SWRConfig
            value={{
                fetcher,
            }}
        >
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AuthProvider
                        session={pageProps.session}
                        refetchInterval={5 * 60}
                    >
                        <ThemeProvider theme={lightTheme}>
                            <SnackbarProvider>
                                <SessionSyncProvider>
                                    {process.env.NODE_ENV !== 'development' && (
                                        <>
                                            <Script
                                                dangerouslySetInnerHTML={{
                                                    __html: fullStoryScript,
                                                }}
                                            />

                                            {!hotjarExcludedRoutes.includes(
                                                router.route
                                            ) && (
                                                <Script
                                                    dangerouslySetInnerHTML={{
                                                        __html: hotjarScript,
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}

                                    <GlobalStyles />
                                    <ErrorBoundary>
                                        <Component {...pageProps} />
                                    </ErrorBoundary>
                                </SessionSyncProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </AuthProvider>
                </PersistGate>
            </ReduxProvider>
        </SWRConfig>
    );
}
export default MyApp;
