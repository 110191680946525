import * as Sentry from '@sentry/nextjs';

function logErrorToSentry(error, errorInfo) {
    console.log('[Sentry Util - Log (Error)] :>> ', error, errorInfo);
    Sentry.captureException(error);
}

export const SentryUtil = {
    logError: logErrorToSentry,
};
