export default async function fetcher(url: string) {
    if (process.env.DEBUG == 'true') console.debug('[FETCH-GET] ', url);
    const r = await fetch(url);
    return await r.json();
}

export async function fetcherPost(
    url = '',
    data = {},
    mode: RequestMode = 'same-origin'
) {
    if (process.env.DEBUG == 'true') console.debug('[FETCH-POST] ', url);
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: mode, // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    const parsedResponse = await response.json();
    return parsedResponse; // parses JSON response into native JavaScript objects
}

export async function fetcherPostForm(
    url = '',
    data = {},
    mode: RequestMode = 'same-origin'
) {
    const form_data = new FormData();
    for (let key in data) {
        form_data.append(key, data[key]);
    }
    if (process.env.DEBUG == 'true') console.debug('[FETCH-POST-FORM] ', url);
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: mode, // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        // headers: {
        //     // 'Content-Type': 'application/json',
        //     // 'Content-Type': 'application/x-www-form-urlencoded',
        //     // 'Content-Type': 'multipart/form-data',
        // },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // @ts-ignore
        body: form_data, // body data type must match "Content-Type" header
    });
    return response; // parses JSON response into native JavaScript objects
}
